


































































































































































































































































































import mixins from 'vue-typed-mixins'
import calendarV2Actions from '@/calendesk/sections/section/calendars/calendarV2/mixins/calendarV2Actions'
import CalendarPanels from '@/calendesk/sections/section/calendars/calendarV2/components/CalendarPanels.vue'
import LocationItem from '@/components/LocationItem.vue'
import CTimeZonesSelect from '@/components/CTimeZonesSelect.vue'
import CTimeZoneTextButton from '@/components/CTimeZoneTextButton.vue'
import { getRgbaColor } from '@/calendesk/prototypes/getRgbaColor'

export default mixins(calendarV2Actions).extend({
  name: 'CalendarV2',
  components: {
    CTimeZoneTextButton,
    CTimeZonesSelect,
    LocationItem,
    CalendarPanels
  },
  computed: {
    gradientStyle () {
      const baseColorHex = this.$vuetify.theme.themes.light.wb_color_bg__color__ as string
      const rgbaColor = getRgbaColor(baseColorHex, 0)
      return {
        '--dynamic-gradient': `linear-gradient(to bottom, ${rgbaColor}, ${baseColorHex})`
      }
    }
  },
  mounted () {
    this.initCalendar()
  }
})
