


















































import mixins from 'vue-typed-mixins'
import DraftElement from '@/calendesk/sections/section/mixins/DraftElement'
import VCardWrapper from '@/calendesk/sections/section/calendars/calendarV2/components/VCardWrapper.vue'
import SelectableCategory from '@/calendesk/models/SelectableCategory'

export default mixins(DraftElement).extend({
  components: {
    VCardWrapper
  },
  props: {
    selectableCategory: {
      type: SelectableCategory,
      default: null,
      required: true
    },
    textColor: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: null
    },
    disableChanging: {
      type: Boolean,
      default: false
    },
    changeMode: {
      type: Boolean,
      default: false
    },
    classForViewRound: {
      type: String,
      default: ''
    },
    isExpanded: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggle () {
      this.$emit('toggle', this.changeMode ? null : this.selectableCategory)
    }
  }
})
